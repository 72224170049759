pre code {
  font-size: 75%;
}

/***************************/
/* Main Layout             */

// .layout-outlet-row {
//   //height: 100vh;
//   flex-direction: column;
// }

// main > .container-fluid {
//   padding: 75px 0 0;
//   height: 100vh;
// }

/*
.footer {
	background-color: #f5f5f5;
}

.footer > .container {
	padding-right: 16px;
	padding-left: 16px;
}
*/
